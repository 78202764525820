<template>
    <v-container>
        <base-material-card icon="mdi-account-multiple" :title="`Sucursal para ${cliente.razon_social ? cliente.razon_social : 'cliente'}`" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre" v-model="sucursal.nombre" :error-messages="errors.nombre" class="required"></v-text-field>
                            <v-select v-model="sucursal.lista_id" :items="listas" label="Listas de precio" item-value="id" item-text="nombre" :error-messages="errors.lista_id" class="required"></v-select>
                            <v-select v-model="sucursal.vendedor_id" :items="vendedores" label="Vendedor" item-value="id" item-text="razon_social" :error-messages="errors.vendedor_id" class="required"></v-select>
                            <v-text-field label="Encargado" v-model="sucursal.encargado" :error-messages="errors.encargado" class="required"></v-text-field>
                            <v-text-field label="Email" v-model="email" :error-messages="errors.email" @keyup.enter="addEmail" append-outer-icon="fa fa-plus" @click:append-outer="addEmail" class="required"></v-text-field>
                            <v-list subheader>
                                <v-list-item v-for="(item, i) in sucursal.email" :key="item">
                                    <v-list-item-icon>
                                        <v-icon>far fa-envelope</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item"></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn icon @click="selectDefaultEmail(i)">
                                            <v-icon v-if="i == 0">fa fa-star</v-icon>
                                            <v-icon v-if="i >  0">far fa-star</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action>
                                        <v-btn icon @click="removeEmail(i)">
                                            <v-icon>far fa-trash-alt</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-text-field label="Teléfono" type="number" v-model="telefono" :error-messages="errors.telefono" @keyup.enter="addTelefono" append-outer-icon="fa fa-plus" @click:append-outer="addTelefono" class="required"></v-text-field>
                            <v-list subheader>
                                <v-list-item v-for="(item, i) in sucursal.telefono" :key="item">
                                    <v-list-item-icon>
                                        <v-icon>far fa-address-book</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item"></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn icon @click="selectDefaultTelefono(i)">
                                            <v-icon v-if="i == 0">fa fa-star</v-icon>
                                            <v-icon v-if="i >  0">far fa-star</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action>
                                        <v-btn icon @click="removeTelefono(i)">
                                            <v-icon>far fa-trash-alt</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Calle" v-model="sucursal.calle" :error-messages="errors.calle">
                            </v-text-field>
                            <v-text-field label="Núm. Exterior" v-model="sucursal.num_exterior" :error-messages="errors.num_exterior"></v-text-field>
                            <v-text-field label="Núm. Interior" v-model="sucursal.num_interior" :error-messages="errors.num_interior"></v-text-field>
                            <v-text-field label="Colonia" v-model="sucursal.colonia" :error-messages="errors.colonia">
                            </v-text-field>
                            <v-text-field label="Localidad" v-model="sucursal.localidad" :error-messages="errors.localidad"></v-text-field>
                            <v-text-field label="Municipio" v-model="sucursal.municipio" :error-messages="errors.municipio"></v-text-field>
                            <v-select v-model="sucursal.estado_id" :items="estados" label="Estado" item-value="id" item-text="nombre" :error-messages="errors.estado_id"></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                    <v-subheader>Addenda</v-subheader>
                    <v-row>
                        <v-col cols="12">
                            <v-select v-model="sucursal.addenda_id" @change="campos = []; loadCampos()" clearable label="Addenda" :items="addendas" item-value="id" item-text="nombre"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader>Campos de addenda</v-subheader>
                            <v-text-field v-for="(campo, i) in campos" v-model="campo.valor" :label="campo.nombre" :disabled="!campo.fillable" :key="campo.i" :error-messages="errors[`addenda_campos.${i}.valor`]"></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                    <v-subheader>Ubicación</v-subheader>
                    <v-row>
                        <v-col cols="12" ref="map" class="map">

                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="$router.push({name: 'ClienteSucursal', params: {cliente: $route.params.cliente}})" :loading="loading">Cancelar</v-btn>
                <v-btn color="primary" @click="save" :loading="loading">Guardar</v-btn>
            </v-card-actions>
        </base-material-card>
    </v-container>
</template>
<script>
    import GoogleMapsApiLoader from 'google-maps-api-loader'
    export default {
        data: () => ({
            listas: [],
            vendedores: [],
            estados: [],
            addendas: [],
            campos: [],
            email: null,
            telefono: null,
            sucursal: {
                email: [],
                telefono: [],
            },
            errors: {},
            loading: false,
            google: false,
            marker: null,
            location: {
                lat: 0,
                lng: 0,
            },
            map: null,
            cliente: {}
        }),
        async mounted() {
            this.setLocation()
            this.loadVendedores()
            this.loadListas()
            this.loadEstados()
            this.loadAddendas()
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: "AIzaSyCfzn_2sG3lDZ25GKnpA__6w98hjr_HGS0"
            })
            this.google = googleMapApi
            if (this.$route.params.sucursal) {
                this.load()
            }
            this.initializeMap()
            this.loadCliente()
        },
        methods: {
            setLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }
                    });
                }
            },
            initializeMap() {
                const mapContainer = this.$refs.map
                this.map = new this.google.maps.Map(
                    mapContainer, {
                    center: this.location,
                    zoom: 16
                })
                this.marker = new google.maps.Marker({
                    position: this.location,
                    map: this.map,
                    draggable: true
                })
                this.marker.addListener('dragend', (event) => {
                    console.log(event)
                    this.sucursal.ubicacion = {
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                    }
                })
            },
            loadAddendas() {
                this.$http.get('/addendas/all').then(response => {
                    this.addendas = response.data
                })
            },
            loadCampos() {
                this.campos = []
                if (this.sucursal.addenda_id) {
                    this.$http.get(`/addendas/${this.sucursal.addenda_id}/campos`).then(response => {
                        this.preProcessCampos(response.data)
                    })
                }
            },
            loadVendedores() {
                this.$http.get('/vendedores/all').then(response => {
                    this.vendedores = response.data
                })
            },
            loadListas() {
                this.$http.get('/listas/all').then(response => {
                    this.listas = response.data
                })
            },
            loadEstados() {
                this.$http.get('/estados/all').then(response => {
                    this.estados = response.data
                })
            },
            load() {
                this.$http.get(`/sucursales/${this.$route.params.sucursal}`).then(response => {
                    this.sucursal = response.data
                    if (this.sucursal.addenda_id) {
                        this.loadCampos()
                    }
                    if (this.sucursal.ubicacion) {
                        this.location = this.sucursal.ubicacion
                    }
                    this.initializeMap()
                })
            },
            save() {
                this.errors = {}
                this.loading = true
                this.sucursal.addenda_campos = this.campos.filter(campo => {
                    return campo.fillable
                })
                if (this.$route.params.sucursal) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post(`/clientes/${this.$route.params.cliente}/sucursales`, this.toFormData(this.sucursal)).then(response => {
                    this.$router.push({ name: 'ClienteSucursal', params: { cliente: this.$route.params.cliente } })
                }).catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            update() {
                this.$http.post(`/sucursales/${this.$route.params.sucursal}`, this.toFormData(this.sucursal, 'PUT')).then(response => {
                    this.$router.push({ name: 'ClienteSucursal', params: { cliente: this.$route.params.cliente } })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            addEmail() {
                if (this.email) {
                    this.sucursal.email.push(this.email)
                    this.email = null
                }
            },
            removeEmail(index) {
                this.sucursal.email.splice(index, 1)
            },
            selectDefaultEmail(i) {
                let primary = this.sucursal.email[i]

                this.sucursal.email.splice(i, 1)
                this.sucursal.email.unshift(primary)
            },
            addTelefono() {
                if (this.telefono) {
                    this.sucursal.telefono.push(this.telefono)
                    this.telefono = null
                }
            },
            removeTelefono(index) {
                this.sucursal.telefono.splice(index, 1)
            },
            selectDefaultTelefono(i) {
                let primary = this.sucursal.telefono[i]

                this.sucursal.telefono.splice(i, 1)
                this.sucursal.telefono.unshift(primary)
            },
            toFormData(object, method) {

                if (method) {
                    object._method = method
                }

                return object
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key]) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                            continue
                        }

                        if (typeof (object[key]) == "object") {
                            for (const subkey in object[key]) {
                                formData.append(`${key}[${subkey}]`, object[key][subkey])
                            }
                            continue
                        }

                        formData.append(key, object[key])
                    }
                }
                return formData
            },
            preProcessCampos(campos) {
                this.campos = campos.map(campo => {
                    campo.valor = null

                    if (this.sucursal.addenda_campos) {
                        let valor = this.sucursal.addenda_campos.find(addenda_campo => {
                            return addenda_campo.id == campo.id
                        })

                        if (valor) {
                            campo.valor = valor.pivot.valor
                        }
                    }

                    return campo
                })
            },
            loadCliente() {
                let id = this.$route.params.cliente ? this.$route.params.cliente : this.sucursal.cliente_id
                this.$http.get(`/clientes/${id}`).then(response => {
                    this.cliente = response.data
                })
            }
        }
    }
</script>
<style>
    .map {
        min-height: 600px;
    }
</style>